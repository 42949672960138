import styled from 'styled-components';
import { Grid, GridRow } from '@naf/grid';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';
import { DesktopWrapper, MobileWrapper } from '../../utils/Styles/Wrappers';

export const yellow20Border = `1px solid ${nafColor.signature.yellow20}`;
export const neutral1Border = `1px solid ${nafColor.neutral.neutral1}`;

export const Wrapper = styled.nav<{ $menuOffset: number; $isSearchActive?: boolean }>`
  z-index: 15;
  max-width: 1400px;
  box-sizing: border-box;
  margin: 0;
  padding: 0 ${spacing.space16};
  border-radius: 0 0 ${spacing.space8} ${spacing.space8};
  ${({ $isSearchActive }) =>
    $isSearchActive
      ? `
    border-left: ${neutral1Border};
    border-right: ${neutral1Border};
    border-bottom: ${neutral1Border};
    background-color: ${nafColor.signature.white};
    height: 100vh;
    overflow-y: scroll;
    padding-bottom: 200px;
  `
      : `
    border-left: ${yellow20Border};
    border-right: ${yellow20Border};
    border-bottom: ${yellow20Border};
    background-color: ${nafColor.signature.yellow10};
    height: auto;
    overflow-y: hidden;
    padding-bottom: 0;
  `};

  @media (max-width: ${breakpoints.l}) {
    position: sticky;
    left: ${spacing.space16};
    right: ${spacing.space16};
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 200px;
  }

  .margin-top {
    margin-top: ${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      margin-top: ${spacing.space32};
    }
  }
`;

export const Content = styled.div<{ $isSearchActive: boolean }>`
  max-width: 1400px;
  padding: ${spacing.space12} ${spacing.space32} ${spacing.space12} ${spacing.space32};
  margin: auto;
  left: 0;
  right: 0;

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space4} ${spacing.space32} ${spacing.space32} ${spacing.space32};
  }

  @media (max-width: ${breakpoints.m}) {
    padding: 0 ${spacing.space4} ${spacing.space32} ${spacing.space4};
  }
`;

export const StyledGrid = styled(Grid)`
  min-height: 100%;
  align-items: flex-start;
  row-gap: ${spacing.space24};
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: ${breakpoints.l}) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${breakpoints.s}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledGridRow = styled(GridRow)`
  @media (max-width: ${breakpoints.l}) {
    grid-column-gap: ${spacing.space32};
    gap: 0 !important;
  }
`;

export const StyledDesktopWrapper = styled(DesktopWrapper)`
  background-color: ${nafColor.signature.white};
  border-radius: ${spacing.space4};
  padding: ${spacing.space12} 0 0 ${spacing.space12};
`;

export const StyledMobileWrapper = styled(MobileWrapper)`
  @media (max-width: ${breakpoints.m}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${spacing.space8};
    padding-bottom: ${spacing.space32};
  }

  @media (max-width: ${breakpoints.s}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const BottomButtonsWrapper = styled.div`
  padding-top: ${spacing.space16};
  padding-bottom: ${spacing.space32};

  .booking-button {
    width: -webkit-fill-available;
  }

  @media (min-width: ${breakpoints.s}) {
    display: none;
  }
`;
