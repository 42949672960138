import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';

const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 31px 31px 0 rgba(5, 46, 28, 0.1);
  border-radius: 8px;
  margin-bottom: ${spacing.space48};
`;

const AlreadyAMember = styled.div`
  display: flex;
  height: 56px;
  justify-self: flex-start;
  align-self: stretch;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background-color: ${nafColor.primary.dew};

  border-radius: 8px 8px 0 0;

  button {
    margin-right: 8px;
    background: none;
    border: none;
    font-weight: 700;
    text-decoration: underline;
    color: ${nafColor.signature.black};

    &:hover {
      color: ${nafColor.signature.black};
      cursor: pointer;
    }
  }
`;

const MainContentWrapper = styled.div`
  padding: ${spacing.space48} ${spacing.space8};

  @media (min-width: ${breakpoints.l}) {
    padding: ${spacing.space80};
  }
`;

const Header = styled(Text)`
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 11px;
  font-size: 28px;
  font-weight: 900;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  > button {
    margin: ${spacing.space8};
  }
`;

const TextWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  padding: ${spacing.space24} 0;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  text-align: center;
  font-style: italic;
`;

export default {
  Box,
  AlreadyAMember,
  MainContentWrapper,
  Header,
  ButtonWrapper,
  TextWrapper,
};
