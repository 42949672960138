import styled, { css } from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { GridCol, GridRow } from '@naf/grid';
import { Button } from '@naf/button';
import { TransitionGroup } from 'react-transition-group';

// Need this empty wrapper for SchemaArticle script
const Wrapper = styled.div``;

const Header = styled.div`
  width: fit-content;
  padding-right: ${spacing.space32};
  margin-left: -${spacing.space32};
  background-color: ${({ theme }) => (theme.background ? theme.background.default : 'inherit')};

  @media (max-width: ${breakpoints.m}) {
    margin-left: -${spacing.space12};
    padding-right: ${spacing.space12};
  }
`;

const ImageWrapper = styled.div`
  margin-top: 0;
  padding: 0 !important;
`;

const ImageFigure = styled.figure`
  margin: 0;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
  }
`;

const ImagePlaceholder = styled.img<{ hasImage?: boolean }>`
  width: 100%;
  max-width: 66.6667%;
  object-fit: cover;
  height: 110%;
  margin-bottom: -18px;
  display: none;
  ${(props) => (props.hasImage ? 'display: none' : 'display: block')}
`;

const VideoWrapper = styled.div`
  margin: 0;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  video {
    outline: none;
  }

  svg {
    color: #1a1a1a;
    position: absolute;
    width: 60px;
    height: 60px;
    top: calc(50% - 30.5px);
    left: calc(50% - 48.5px);
    background-color: rgb(255 255 255 / 80%);
    border-radius: 50%;
    text-align: center;
    line-height: 6.25rem;
    vertical-align: middle;
    padding: 18px;
  }

  &:hover {
    svg {
      background-color: rgb(255 255 255 / 100%);
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: calc(100% + 48px);
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};

    svg {
      margin-left: -32px;
      width: 30px;
      height: 30px;
      top: calc(50% - 24.5px);
      left: calc(50% - 24.5px);
      padding: 9px;
    }
  }
`;

const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
  width: 96px;
  margin: ${spacing.space4} 0 ${spacing.space48};
  color: ${nafColor && nafColor.signature.yellow ? nafColor.signature.yellow : `#FFDB16`};
`;

const ReadMoreHRLine = styled(HRLine)``;

const Title = styled(Text)<{ videoPlaying: boolean }>`
  position: relative;
  width: 100%;
  transition: margin 700ms;
  background-color: ${({ theme }) => (theme.background ? theme.background.default : 'inherit')};
  padding: 0 12px;
  min-height: 50px;
  font-size: 2rem;
  margin-top: 0;

  @media (min-width: ${breakpoints.m}) {
    margin-left: 0;
    padding: 0 32px;
    font-size: 3rem;
  }

  @media (min-width: ${breakpoints.l}) {
    margin-bottom: 25px;
  }
`;

const Ingress = styled(Text)`
  margin-top: 0;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.m}) {
    font-size: 1.25rem;
  }
`;

const StyledGridCol = styled(GridCol)`
  padding-top: 0;
`;

const NoMarginText = styled(Text)`
  margin: 0;
`;

const Logo = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 12px;
`;

const StyledText = styled(Text)`
  margin-top: -3px;
  margin-bottom: 5px;
  margin-left: 13px;
`;

const CategoryTitle = styled(Text)`
  margin-bottom: 25px;
`;

const Body = styled.div`
  margin-bottom: ${spacing.space40};

  & > div:first-child {
    max-width: 100%;

    & > :not(h3):first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    max-width: 576px;
  }

  h3:not(.accordion *) {
    margin-bottom: -4px;
  }

  p {
    margin: 25px 0;
    max-width: 576px;

    a:hover {
      color: ${nafColor.primary.park};
    }
  }

  p + h2,
  ul + h2,
  ol + h2,
  table + h2 {
    margin-top: 54px;
  }

  h2 + p,
  h2 + ul,
  h2 + table,
  h2 + ol {
    margin-top: 29px;
  }
`;

const ScoreWrapper = styled.div`
  margin-bottom: 48px;

  h3 {
    margin-bottom: 15px;
  }
`;

const ScoreIconWrapper = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  flex-direction: row;
  margin-bottom: 25px;

  svg {
    width: 24px;
    margin-right: 13px;
    padding: 0;
  }

  b {
    font-weight: 500;
  }
`;

const BylineRole = styled.span`
  font-style: italic;
`;

const RelatedList = styled.ul`
  padding: 0;
  margin: 0;
`;

const RelatedListItem = styled.li`
  padding-top: 24px;
  list-style-type: none;

  a {
    padding-bottom: 24px !important;
  }
`;

const TagsWrapper = styled.div`
  .tags-chip {
    margin-left: -${spacing.space4};
    margin-right: ${spacing.space4};
  }
`;

const ShowMoreButtonWrapper = styled.div`
  margin-top: 0;
  display: flex;
  align-items: center;

  p {
    margin-left: 20px;
  }
`;

const CardGridWrapper = styled(GridCol)`
  margin-bottom: 30px;

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: 0;
  }
`;

const InternalWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Content = styled.div`
  box-sizing: border-box;
  max-width: 1216px;
  margin: auto;
  padding: 0 48px;
  flex-direction: column;
`;

const ColoredBox = styled.div`
  position: relative;
  padding-top: 80px;
  padding-bottom: 103px;

  @media (max-width: ${breakpoints.m}) {
    padding: ${spacing.space64} ${spacing.space56};

    h2 {
      margin: 0;
      margin-bottom: ${spacing.space16};
    }
  }

  @media (max-width: ${breakpoints.s}) {
    padding: ${spacing.space56} 0;

    h2 {
      margin-bottom: ${spacing.space8};
    }
  }

  &::before {
    content: '';
    background-color: ${nafColor.primary.dew};
    margin-left: -50vw;
    position: absolute;
    width: 150vw;
    height: 100%;
    z-index: -1;
    top: 0;

    @media (max-width: ${breakpoints.m}) {
      width: 100vw;
      margin-left: -${spacing.space80};
    }

    @media (max-width: ${breakpoints.s}) {
      margin-left: -${spacing.space24};
    }
  }
`;

const StyledButton = styled(Button)`
  margin-left: 24px;

  @media (max-width: ${breakpoints.l}) {
    margin-left: 0;
    margin-top: 24px;
  }
`;

const SpacedGridRow = styled(GridRow)`
  margin-bottom: 72px;

  @media (max-width: ${breakpoints.m}) {
    margin-bottom: ${spacing.space40};
  }
`;

// Per i dag fungerer ikke designet til CampaignBlock hvis den ikke ligger i en relative container
const RelativeGridRow = styled(GridRow)`
  position: relative;
`;

const SmallCol = styled(GridCol)`
  @media (min-width: ${breakpoints.m}) {
    display: none;
  }
`;

const LargeCol = styled(GridCol)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const TransitionGrid = styled(TransitionGroup)`
  grid-column: 1 / -1;
`;

const EmptyMessagesWrapper = styled.div`
  text-align: center;
  padding-top: ${spacing.space48};
  padding-bottom: ${spacing.space64};

  p {
    margin-top: ${spacing.space8};
  }

  h2 {
    margin-bottom: ${spacing.space8};
  }

  div {
    margin: 0 auto;
  }
`;

const ImageGridCol = styled(GridCol)`
  img {
    object-fit: contain;
    max-width: 100%;
  }
`;

const ImageColWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: ${spacing.space8};

  @media (min-width: ${breakpoints.m}) {
    height: 470px;
  }

  @media (min-width: ${breakpoints.l}) {
    height: inherit;
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100vw;
    margin-left: -${spacing.space24};
  }

  & > img {
    width: 100%;
  }
`;

const ThreeColumnCol = styled(GridCol)`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.s}) {
    justify-content: flex-start;
  }
`;

const QuoteCol = styled(GridCol)`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.s}) {
    justify-content: flex-start;
  }
`;

const BodyCol = styled(GridCol)<{ $hasImage?: boolean }>`
  margin: -109px 0 ${spacing.space24} ${spacing.space64};
  padding: 0 ${spacing.space32};
  background-color: ${nafColor.signature.white};

  h1 {
    margin-bottom: ${spacing.space24};
  }

  @media (max-width: ${breakpoints.m}) {
    margin: 0 ${spacing.space24} ${spacing.space8};
    padding: 0 ${spacing.space32};
    margin-top: -72px;

    ${({ $hasImage }) =>
      !$hasImage &&
      css`
        margin-top: 0;
      `}
  }

  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    margin: -331px 0 0 -${spacing.space12};
    padding: 0 ${spacing.space12};

    ${({ hasImage }) =>
      !hasImage &&
      css`
        margin-top: 0;
      `}
  }

  ${({ hasImage }) =>
    !hasImage &&
    css`
      margin-top: 0;
    `}
`;

const RelatedProductText = styled(Text)`
  margin: 0;
  margin-top: ${spacing.space16};
`;

const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.space48};
  margin-bottom: ${spacing.space40};

  h2 {
    margin: 0;
  }
`;

const Caption = styled(Text)`
  margin: 0;
  margin-top: ${spacing.space16};
`;

export default {
  ImageWrapper,
  ImageFigure,
  ImagePlaceholder,
  Wrapper,
  Header,
  Title,
  Ingress,
  NoMarginText,
  Logo,
  StyledText,
  Body,
  BylineRole,
  RelatedList,
  RelatedListItem,
  VideoWrapper,
  TagsWrapper,
  HRLine,
  ShowMoreButtonWrapper,
  CategoryTitle,
  ReadMoreHRLine,
  CardGridWrapper,
  StyledGridCol,
  ScoreWrapper,
  ScoreIconWrapper,
  Content,
  InternalWrapper,
  ColoredBox,
  StyledButton,
  SpacedGridRow,
  SmallCol,
  LargeCol,
  TransitionGrid,
  EmptyMessagesWrapper,
  RelativeGridRow,
  ImageGridCol,
  ImageColWrapper,
  ThreeColumnCol,
  QuoteCol,
  BodyCol,
  RelatedProductText,
  ReadMoreWrapper,
  Caption,
};
