import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing } from '@naf/theme';
import { SeriesOptionsType, Options, YAxisPlotLinesOptions } from 'highcharts';
import { HighChartComponent } from './HighChartComponent';
import { BaseContainerProps } from '../../../../../types/baseContainerProps';
import { isNonNullable } from '../../../lib/isNonNullable';
import { ChargeTest } from '../../../../../types/carModelType';
import { Season } from '../../../../../types/ElPrixContextData';

type ChargeTestData = Pick<ChargeTest, 'readings' | 'startPercent' | 'endPercent'>;

type Props = {
  winterData?: ChargeTestData;
  summerData?: ChargeTestData;
  plotLines?: YAxisPlotLinesOptions[];
  asBlock?: boolean;
  className?: any;
};

const mapData = (readings: Record<`reading${string}`, number>) =>
  Object.entries(readings)
    .map(([key, value]) => ({
      x: parseInt(key.substring(7), 10),
      y: value,
    }))
    .sort((a, b) => a.x - b.x);

const Plot = ({ plotLines, asBlock, className, winterData, summerData }: Props) => {
  const options = useMemo((): Options => {
    const seriesObject: Record<Season, SeriesOptionsType | null> = {
      winter: null,
      summer: null,
    };

    if (winterData) {
      seriesObject.winter = {
        name: `kW (effekt) vinter (${winterData.startPercent}-${winterData.endPercent}%)`,
        type: 'line',
        data: mapData(winterData.readings ?? {}),
        color: nafColor.information.information,
        zIndex: 8,
        className: 'winter',
        marker: {
          lineColor: nafColor.information.information,
          lineWidth: 1,
          states: {
            hover: {
              fillColor: nafColor.information.information,
            },
          },
        },
      };
    }
    if (summerData) {
      seriesObject.summer = {
        name: `kW (effekt) summer (${summerData.startPercent}-${summerData.endPercent}%)`,
        type: 'line',
        // {key: readingXYZ, value: number}
        data: mapData(summerData.readings ?? {}),
        color: nafColor.information.successDark,
        zIndex: 8,
        className: 'summer',
        marker: {
          lineColor: nafColor.information.successDark,
          lineWidth: 1,
          states: {
            hover: {
              fillColor: nafColor.information.successDark,
            },
          },
        },
      };
    }

    const series = Object.values(seriesObject).filter(isNonNullable);
    const seriesOrUndefined = !!series && series.length > 0 ? series : undefined;

    return {
      title: {
        text: undefined,
      },
      chart: {
        backgroundColor: 'rgba(255,255,255,0)',
      },
      plotOptions: {
        line: {
          lineWidth: 1.5,
          marker: { enabled: true, symbol: 'circle', radius: 4, fillColor: 'white' },
        },
      },
      tooltip: {
        enabled: true,
        followPointer: true,
        shadow: false,
        useHTML: true,
        padding: 0,
        borderWidth: 0,

        formatter: function formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          const effect = this.y ? this.y.toFixed(0).toString().replace('.', ',') : 0;
          // eslint-disable-next-line react/no-this-in-sfc
          const percentage = this.x ? (this.x as number).toFixed(0).toString().replace('.', ',') : 0;

          return `<table>
          <tr>${percentage}% oppladet</tr>
          <br/>
          <tr>${effect} kW ladeeffekt</tr>
          </table>`;
        },
      },
      credits: {
        enabled: false,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      series: seriesOrUndefined,
      xAxis: {
        title: {
          text: undefined,
        },
        labels: {
          style: {
            color: nafColor.neutral.neutral4,
            fontSize: '0.75rem',
          },
          formatter: function formatter() {
            const { isLast, value } = this;
            return isLast ? '%' : `${value}`;
          },
        },
        gridLineWidth: 1,
        gridLineDashStyle: 'Dash',
        min: 0,
        max: 90,
      },
      yAxis: {
        allowDecimals: false,
        min: 0,
        lineWidth: 1,
        lineColor: nafColor.neutral.neutral3,
        title: {
          text: undefined,
        },
        labels: {
          formatter: function formatter() {
            const { isLast, value } = this;
            return isLast ? 'kW' : `${value}`;
          },
          style: {
            color: nafColor.neutral.neutral4,
            fontSize: '0.75rem',
          },
        },
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        plotLines,
      },
      legend: {
        enabled: false,
      },
    };
  }, [plotLines, summerData, winterData]);

  return winterData || summerData ? (
    <Figure asBlock={asBlock}>
      <Container withBorder={!asBlock}>
        <Legend>
          {winterData ? (
            <LegendItem backgroundColor={nafColor.information.information}>
              Ladeeffekt (kW) vinter ({winterData.startPercent}-{winterData.endPercent}%)
            </LegendItem>
          ) : null}
          {summerData ? (
            <LegendItem backgroundColor={nafColor.primary.park}>
              Ladeeffekt (kW) sommer ({summerData.startPercent}-{summerData.endPercent}%)
            </LegendItem>
          ) : null}
        </Legend>
        <HighChartComponent containerProps={{ className }} options={options} />
      </Container>
    </Figure>
  ) : null;
};

const Figure = styled.figure<{ asBlock?: boolean }>`
  margin: 0 0 ${({ asBlock }) => (asBlock ? '0' : spacing.space48)};
`;

const Container = styled.div<{ withBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ withBorder }) =>
    withBorder
      ? css`
          border: 1px solid ${nafColor.neutral.neutral1};
          padding: ${spacing.space24};
          margin-bottom: 17px;
        `
      : null}
`;

export const Legend = styled.div`
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 21px;
`;

type WrapperProps = { backgroundColor: string } & BaseContainerProps;

const LegendItem = styled.div<WrapperProps>`
  position: relative;
  left: 24px;
  font-size: 0.875rem;
  font-weight: normal;
  margin-right: 34px;

  &::before {
    position: absolute;
    top: 10px;
    left: -12px;
    content: '';
    width: 8px;
    height: 8px;

    background-color: ${(props) => props.backgroundColor || 'red'};
  }
`;

export const ChargeCurve = styled(Plot)`
  .normal-charging {
    stroke: ${nafColor.neutral.neutral3} !important;
    width: 1px;
  }

  .highcharts-axis-labels {
    font-family: questa-sans, sans-serif;
    font-weight: 700;
  }

  .highcharts-plot-line-label {
    font-family: questa-sans, sans-serif;
    fill: ${nafColor.neutral.neutral4};
    font-weight: 700;
    line-height: 0.875rem;
    letter-spacing: 0.48px;
  }

  .highcharts-tooltip > span {
    font-family: questa-sans, sans-serif;
    background: white !important;
    padding: ${spacing.space8};
    border-radius: 2px;
    border: 1px solid ${nafColor.neutral.neutral2};
  }
`;
