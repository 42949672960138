import React from 'react';
import NafBirthdayDesktop from '../../../../../../assets/decorations/nafBirthday/NafBirthdayDesktop.svg?url';
import NafBirthdayMobile from '../../../../../../assets/decorations/nafBirthday/NafBirthdayMobile.svg?url';
import * as S from './Styles';

export const NafBirthday = ({ isScrolled }: { isScrolled?: boolean }) => (
  <>
    <S.DesktopWrapper isScrolled={isScrolled}>
      <img src={NafBirthdayDesktop} alt="Den gamle NAF-logoen" />
    </S.DesktopWrapper>
    <S.MobileWrapper>
      <img src={NafBirthdayMobile} alt="Den gamle NAF-logoen" />
    </S.MobileWrapper>
  </>
);
