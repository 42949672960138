import React from 'react';
import { TextVariant } from '@naf/text';
import { useHistory } from 'react-router';
import { Button } from '@naf/button';
import { useAuth0 } from '@auth0/auth0-react';
import S from './styles';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { PaywallOption } from '../../../../types/paywallOptionType';
import { PaywallLink } from '../../../../types/paywallLinkType';

type Props = {
  paywallOptions: PaywallOption;
  paywallLink?: PaywallLink;
};

export const PaywallBox = ({ paywallLink, paywallOptions }: Props) => {
  const history = useHistory();
  const internalURL = useDocumentUrlWithNode(paywallLink?.data);
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const onloginClick = () => {
    loginWithRedirect({ appState: { returnTo: `${window.location.origin}${history.location.pathname}` } });
  };

  return (
    <S.Box>
      {!isAuthenticated && (
        <S.AlreadyAMember>
          <span>Allerede medlem? </span>
          <button type="button" onClick={onloginClick}>
            Logg inn her for tilgang
          </button>
        </S.AlreadyAMember>
      )}
      <S.MainContentWrapper>
        <S.Header variant={TextVariant.Header3}>{paywallOptions.title}</S.Header>
        {paywallOptions.description && <S.TextWrapper>{paywallOptions.description}</S.TextWrapper>}
        <S.ButtonWrapper>
          <Button
            onClick={(e: any) => {
              e.preventDefault();
              window.dataLayer?.push({
                event: 'cta_click',
                cta_text: paywallOptions.ctaText,
                cta_location: history.location.pathname,
              });
              history.push(internalURL);
            }}
          >
            {paywallOptions.ctaText}
          </Button>
        </S.ButtonWrapper>
      </S.MainContentWrapper>
    </S.Box>
  );
};
